export default {
    REWARD_TYPES: [
        {
            name: '7_DAY_LOGIN',
            id: 'login'
        },
        {
            name: 'DAILY_LOGIN',
            id: 'daily-login'
        },
        {
            name: 'FIRST_PURCHASE',
            id: 'first-purchase'
        }
    ],
    ALL_REWARD_TYPES: [
        {
            text: "Gold",
            value: 0
        },
        {
            text: "Gem",
            value: 1
        },
        {
            text: "Chest",
            value: 2
        },
        {
            text: "Unit",
            value: 3
        },
        {
            text: "Hero",
            value: 4
        },
        {
            text: "Card",
            value: 5
        },
        {
            text: "Emoji",
            value: 6
        },
        {
            text: "Token",
            value: 7
        },
        {
            text: "Pick One of Two",
            value: 8
        },
        {
            text: "Picked Unit",
            value: 10
        },
        {
            text: "Picked Hero",
            value: 11
        },
        {
            text: "Zero to Hero Pts",
            value: 12
        },
        {
            text: "Random Unit from Pool",
            value: 13
        },
        {
            text: "Avatar",
            value: 14
        },
        {
            text: "Robust Soft Currency",
            value: 15
        },
        {
            text: "Robust Hard Currency",
            value: 16
        },
        {
            text: "Thematic Soft Currency",
            value: 17
        },
        {
            text: "Thematic Hard Currency",
            value: 18
        },
        {
            text: "Thematic Pass Token",
            value: 19
        },
        {
            text: "Thematic Collection Card",
            value: 20
        },
    ],
    ORNAMENTS_RARITY: [
        {
            text: "Silver",
            value: 0
        },
        {
            text: "Gold",
            value: 1
        },
        {
            text: "Diamond",
            value: 2
        }
    ],
    AMOUNT_TYPE: [
        {
            text: "Exact",
            value: 0
        },
        {
            text: "Btw 0 and Amount",
            value: 1
        },
        {
            text: "Btw Min Amount and Amount",
            value: 2
        }
    ]
}