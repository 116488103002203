<template>
  <div class="main-container">
    <h1 class="main-heading">{{ $t("REWARDS") }}</h1>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t("REWARD_VALUES.ID") }}</div>
          <div class="cell">{{ $t("REWARD_VALUES.NAME") }}</div>
          <div class="cell action-cell">{{ $t("ACTIONS") }}</div>
        </div>
        <div
          class="table-row"
          v-for="(reward, index) in rewardConstants.REWARD_TYPES"
          v-bind:key="reward.id"
          :class="index % 2 === 0 ? '' : 'alternate'"
        >
          <div class="cell">{{ reward.id }}</div>
          <div class="cell">{{ $t("REWARD_VALUES." + reward.name) }}</div>
          <div class="cell action-cell">
            <Button size="sm" :text="$t('EDIT')" :loading="false" type="info" :onClick="() => $router.push({ path: `/rewards/${reward.id}` })" />
          </div>
        </div>
        <div v-if="xpRoadData" class="table-row alternate">
          <div class="cell">{{ "experience" }}</div>
          <div class="cell">{{ "Experience Road" }}</div>
          <div class="cell action-cell">
            <Button size="sm" :text="$t('EDIT')" :loading="false" type="info" :onClick="() => $router.push({ path: `/rewards/experience` })" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, onBeforeMount, onBeforeUnmount, onMounted } from "vue";
import { useStore } from "vuex";
import moment from "moment";

import Button from '../../components/common/Button.vue'
import rewardConstants from "@/constants/rewardConstants";
import dispatchMap from "@/constants/dispatchMap";

export default {
  name: "Rewards",
  components: {
    Button
  },
  setup() {
    const store = useStore();
    const usedPages = ["rewardsIndex"];
    onBeforeMount(() => {
      usedPages.forEach((page) => {
        dispatchMap[page].forEach((dispatchStr) => {
          store.dispatch(dispatchStr);
        });
      });
    });
    const rewardsArr = ref([]);
    const xpRoadData = ref(undefined);

    watch(
      () => store.getters["rewards/getRewards"],
      (value) => {
        rewardsArr.value = [...value];
      },
      { immediate: true }
    );

    watch(
      () => store.getters["road/getExperienceRoad"],
      (value) => {
        xpRoadData.value = value;
        console.log(value);
      },
      { immediate: true }
    );

    onMounted(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    return {
      rewardsArr,
      moment,
      rewardConstants,
      xpRoadData,
    };
  },
};
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {
}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 5fr 1fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  padding: 0 10px;
}


.action-cell {
  display: flex;
}


</style>