<script>

export default {
    props: {
        type: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: false
        },
        text: {
            type: String,
            required: true
        },
        onClick: {
            type: Function,
            required: true
        },
        size: {
            type: String,
            default: 'md'
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        }
    }
}
</script>

<template>
    <button :class="type + ' ' + size" @click="(event) => onClick(event)" :disabled="loading||disabled">
        <div>{{ text }}</div>
        <div v-if="loading"  class="button-spinner">
            <i class="fa fa-spinner fa-spin"></i>
        </div>
    </button>
</template>

<style scoped>

button {
    min-height: 35px;
    min-width: 120px;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    color: white;
    border: none;
    font-size: 14px;
    padding: 5px;
    margin-right: 5px;
}

button.sm {
    min-width: 60px;
    min-height: 30px;
    max-width: 80px;
    max-height: 30px;
}

button.md {
    max-height: 35px;
}

.button-spinner {
    margin-left: 10px;
}

button.info {
    background-color: darkorchid;
}

button.success {
    background-color: green;
}

button.error {
    background-color: darkred;
}

button.export {
    background-color:orange;
}

button:hover{
    cursor: pointer;
    filter: grayscale(40%)
}

button:disabled {
    filter: grayscale(100%);
    cursor: default;
}

</style>